import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import IconOnlyButton from 'components/Buttons/IconOnlyButton';
import Portal from 'utils/Portal';
import useCloseOnOutsideClick from 'utils/hooks/useCloseOnOutsideClick';
import useTrapFocusInsideModal from 'utils/hooks/useTrapFocusInsideModal';

/**
 * Modal component.
 *
 * @param {string} sPortalId - The id of the root element to render the modal in.
 * @param {node} children - The content of the modal. ie. action buttons, text, etc.
 * @param {function} fnSetShowModal - The function to set the state of the modal.
 * @param {string} sFlavor - The flavor of the modal. One of 'sign-in', 'alert', or 'message'.
 * @param {string} sModalTitle - The title of the modal.
 * @param {string} sModalSubtitle - The subtitle of the modal.
 * @param {string or object} sModalDetail - The sub subtitle of the modal.
 *
 * @returns {node} - The modal.
 *
 */

const Modal = ({
  sPortalId,
  children,
  fnSetShowModal,
  sFlavor,
  sModalTitle,
  sModalSubtitle,
  sModalDetail, // ModalSubSubtitle
  sCypressId,
}) => {
  const oModalRef = useRef();
  useCloseOnOutsideClick(oModalRef, fnSetShowModal);
  useTrapFocusInsideModal(oModalRef);
  return (
    <Portal sRootElementId={sPortalId}>
      <div className='modal__overlay'>
        <dialog
          className={`modal modal--${sFlavor}`}
          ref={oModalRef}
          data-cy={sCypressId}
        >
          <IconOnlyButton
            sIcon='close'
            fnHandleClick={() => fnSetShowModal(false)}
            sAriaLabel='Close'
            className='modal__closeButton'
          />
          {sFlavor !== 'message' && (
            <div className={`modal__header modal__header--${sFlavor}`}>
              <p className='modal__title t-paragraph--medium'>{sModalTitle}</p>
            </div>
          )}
          <div className={`modal__content modal__content--${sFlavor}`}>
            {sModalSubtitle && (
              <p className='modal__subtitle t-paragraph--large'>
                {sModalSubtitle}
              </p>
            )}
            {sModalDetail && (
              <div className='modal__detail t-paragraph'>{sModalDetail}</div>
            )}
            {(sModalSubtitle || sModalDetail) && sFlavor !== 'message' && (
              <hr className='modal__theLine' />
            )}
            <div className='modal__children'>{children}</div>
          </div>
        </dialog>
      </div>
    </Portal>
  );
};

// @todo: Convert these to TypeScript. See the declaration file Modal.d.ts.
Modal.propTypes = {
  sPortalId: PropTypes.string,
  children: PropTypes.node,
  fnSetShowModal: PropTypes.func.isRequired,
  sFlavor: PropTypes.oneOf(['sign-in', 'alert', 'message']),
  sModalTitle: PropTypes.string,
  sModalSubtitle: PropTypes.string,
  sModalDetail: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Modal.defaultProps = {
  sPortalId: 'app',
  children: null,
  sFlavor: 'message',
  sModalTitle: '',
  sModalSubtitle: '',
  sModalDetail: '',
};

export default Modal;

import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { CheckBox } from 'components/Form/Fields';
import EventDescription from 'components/EventDetailsPage/EventDescription';
import useIsUserAuthenticated from 'utils/hooks/useIsUserAuthenticated';
import { fnIsEventAtCapacity } from 'components/Events/helpers';
import Button from 'components/Buttons/Button';
import {
  currentUserFirstLastNameSelector,
  currentUserLookupIdSelector,
  selectCurrentUserEmail,
} from 'redux/selectors';
import CancelEventRegistrationModal from 'components/EventDetailsPage/CancelEventRegistration';
import { fnBeginRegistrationCancellation } from 'components/EventDetailsPage/CancelEventRegistration/helpers';
import EventAlert from '../../../Events/EventAlert';
import EventCost from '../../EventCost';
import { fnFormatSubEventDateAndTime } from '../../helpers';
import RsvpButtons from './RsvpButtons';

const SubEventDetailCard = ({ oSubEvent }) => {
  const fnDispatch = useDispatch();
  const formikProps = useFormikContext();
  const [bHasRsvpdNo, fnSetHasRsvpdNo] = useState(false);
  const [
    bShowCancelRegistrationConfirmModal,
    fnSetShowCancelRegistrationConfirmModal,
  ] = useState(false);
  const bIsUserAuthenticated = useIsUserAuthenticated();
  const sCurrentUserLookupId = useSelector(currentUserLookupIdSelector);
  const oCurrentUserName = useSelector(currentUserFirstLastNameSelector);
  const sCurrentUserEmail = useSelector(selectCurrentUserEmail);

  const sFieldValue = JSON.stringify(R.omit(['ATTENDEES'], oSubEvent));
  const bIsSubEventSelected =
    formikProps.values?.subEvents?.includes(sFieldValue);

  const { IS_CANCELLED, START_DATE_TIME, END_DATE_TIME, TIME_ZONE } = oSubEvent;
  const { IS_REGISTERED } = oSubEvent.REGISTRATION;
  const bIsHost = oSubEvent?.MY_PARTY?.HOST?.LOOKUPID === sCurrentUserLookupId;
  const bIsWaitlistEvent = [true, 1].includes(
    oSubEvent.REGISTRATION?.ALLOW_WAITLIST
  );
  const bIsWaitlisted = [true, 1].includes(
    oSubEvent.REGISTRATION?.IS_WAITLISTED
  );

  const { IS_INVITED, RSVP_STATUS, HAS_INVITEES } = oSubEvent.INVITATION;
  const bIsAtCapacity = fnIsEventAtCapacity(oSubEvent);

  let bIsSubEventRegistrable =
    IS_REGISTERED === 0 &&
    !IS_CANCELLED &&
    !bHasRsvpdNo &&
    RSVP_STATUS !== 'Declined' &&
    // Show the checkbox if waitlist is allowed or if the user is not already waitlisted.
    (!bIsAtCapacity || bIsWaitlistEvent) &&
    (!bIsAtCapacity || !bIsWaitlisted);
  // @todo: remove this after Homecoming moves to WakeNetwork event registration
  if (oSubEvent.MAIN_EVENT.LOOKUPID === '8-10010554') {
    // Homecoming 2024
    bIsSubEventRegistrable = false;
  }
  const bShowRsvpButtons =
    (IS_INVITED === 1 && !bHasRsvpdNo && RSVP_STATUS === 'No Reply') ||
    (!bIsUserAuthenticated && !bHasRsvpdNo && HAS_INVITEES === 1);

  return (
    <li
      className={`subEventDetailCard ${
        bIsSubEventSelected ? 'subEventDetailCard--selected' : ''
      }`}
      data-cy={`subevent-${oSubEvent.LOOKUPID}-detail-card`}
    >
      {bIsSubEventRegistrable ? (
        <CheckBox
          sId={oSubEvent.ID}
          sName='subEvents'
          sLabel={oSubEvent.TITLE}
          mValue={sFieldValue}
          bSilenceErrors
        />
      ) : (
        <p className='subEventDetailCard__eventTitle t-paragraph--bold'>
          {oSubEvent.TITLE}
        </p>
      )}
      <div className='subEventDetailCard__details'>
        <div className='subEventDetailCard__detailsMiddle'>
          {fnFormatSubEventDateAndTime(
            START_DATE_TIME,
            END_DATE_TIME,
            TIME_ZONE
          )}
          <p className='subEventDetailCard__location'>
            {oSubEvent.LOCATION.NAME}
          </p>
          <EventCost
            oEvent={oSubEvent}
            sClassName='subEventDetailCard__cost t-paragraph--small'
          />
          <EventAlert oEvent={oSubEvent} />
        </div>
        {oSubEvent?.DESCRIPTION && (
          <EventDescription
            sDescription={oSubEvent.DESCRIPTION}
            bIncludeHeader={false}
            className='subEventDetailCard__description'
          />
        )}
        <div className='subEventDetailCard__detailsMiddleButtonsWrapper'>
          <Link
            to={`/events/${oSubEvent.SLUG}`}
            className='subEventDetailCard__viewMore button--tertiary'
          >
            View More
          </Link>
          {IS_REGISTERED === 1 && bIsHost && (
            <Button
              sFlavor='tertiary'
              fnHandleClick={() =>
                fnBeginRegistrationCancellation(
                  oSubEvent,
                  fnSetShowCancelRegistrationConfirmModal,
                  fnDispatch
                )
              }
              className='subEventDetailCard__cancelRegistrationButton'
            >
              Cancel Registration
            </Button>
          )}
        </div>
      </div>
      {bShowRsvpButtons && (
        <RsvpButtons oSubEvent={oSubEvent} fnSetHasRsvpdNo={fnSetHasRsvpdNo} />
      )}
      {(bHasRsvpdNo || RSVP_STATUS === 'Declined') && (
        <p
          className='subEventDetailCard__rsvpStatus t-paragraph--small'
          data-cy='rsvp-decline-message'
        >
          You declined this invitation.
        </p>
      )}
      {bShowCancelRegistrationConfirmModal && (
        <CancelEventRegistrationModal
          bShowCancelRegistrationConfirmModal={
            bShowCancelRegistrationConfirmModal
          }
          fnSetShowCancelRegistrationConfirmModal={
            fnSetShowCancelRegistrationConfirmModal
          }
        />
      )}
    </li>
  );
};

SubEventDetailCard.propTypes = {
  oSubEvent: PropTypes.shape({
    DESCRIPTION: PropTypes.string,
    ID: PropTypes.string.isRequired,
    LOOKUPID: PropTypes.string.isRequired,
    SLUG: PropTypes.string.isRequired,
    TITLE: PropTypes.string.isRequired,
    REGISTRATION: PropTypes.shape({
      IS_REGISTERED: PropTypes.number,
      TOTAL_CAPACITY: PropTypes.number,
      TOTAL_REGISTRATIONS: PropTypes.number,
      ALLOW_WAITLIST: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
      IS_WAITLISTED: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    }),
    INVITATION: PropTypes.shape({
      IS_INVITED: PropTypes.number,
      RSVP_STATUS: PropTypes.string,
      HAS_INVITEES: PropTypes.number,
    }),
    START_DATE_TIME: PropTypes.string,
    END_DATE_TIME: PropTypes.string,
    TIME_ZONE: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    LOCATION: PropTypes.object,
    IS_CANCELLED: PropTypes.bool,
    IS_INVITED: PropTypes.number,
  }).isRequired,
};

export default SubEventDetailCard;

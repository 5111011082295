import oWnApi from 'utils/WnApi';
import * as CheckoutActions from 'components/Checkout/redux/actions';
import { useDispatch } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

/**
 * Custom hook to fetch the event details.
 *
 * @param {string} sEventSlug - The event slug
 * @param {bool} bIsAuthenticated - Whether the user is authenticated or not
 * @returns {object} - { oEvent, sError, bIsEventDetailsLoading, fnRefreshEventDetails }
 */
const useEventDetails = (sEventSlug, bIsAuthenticated) => {
  const fnDispatch = useDispatch();
  const [oEvent, fnSetEvent] = useState(null);
  const [sError, fnSetError] = useState('');
  const [bIsLoading, fnSetIsLoading] = useState(true);

  // Function to fetch event details
  const fnGetEventDetails = useCallback(async () => {
    if (!sEventSlug) {
      fnSetError('Invalid event slug.');
      fnSetIsLoading(false);
      return;
    }

    fnSetIsLoading(true);
    fnSetError('');

    try {
      const oResponse = await oWnApi.get(
        `events/${sEventSlug}`,
        bIsAuthenticated
      );
      if (oResponse.status === 200) {
        fnSetEvent(oResponse.data);
      } else {
        fnSetError(oResponse.data?.message || 'Failed to fetch event details.');
      }
    } catch (oError) {
      console.error(oError);
      fnSetError('Something went wrong. Please try again later.');
    } finally {
      fnSetIsLoading(false);
    }
  }, [sEventSlug, bIsAuthenticated]);

  // Fetch event details when the slug or authentication status changes
  useEffect(() => {
    fnDispatch(CheckoutActions.reset({})); // Clear the cart
    fnGetEventDetails();
  }, [sEventSlug, bIsAuthenticated, fnDispatch, fnGetEventDetails]);

  // Function to manually refresh event details
  const fnRefreshEventDetails = useCallback(() => {
    fnGetEventDetails();
  }, [fnGetEventDetails]);

  return {
    oEvent,
    sError,
    bIsLoading,
    fnRefreshEventDetails,
  };
};

export default useEventDetails;

// helpers/modalContentHelpers.js
import React from 'react';
import { fnAddEventRegistrationCancellationToCart } from 'components/EventRegistration/redux/eventHelpers';
import * as CheckoutActions from 'components/Checkout/redux/actions';
import store from 'redux/store';
import {
  currentUserFirstLastNameSelector,
  selectCurrentUserEmail,
  currentUserLookupIdSelector,
} from 'redux/selectors';

/**
 * Constants for modal messages and details used in the cancellation confirmation modal.
 */

export const oModalMessages = {
  confirm:
    'Are you sure you want to cancel the event registration for the following event(s):',
  success: 'The event registration has been canceled.',
  error: 'Something went wrong. Please try again.',
};

/**
 * Generates modal content based on the cancellation phase.
 *
 * @param {Array} aEventRegistrationsToCancel - Array of event registrations to cancel.
 * @param {boolean} bHasCost - Whether the event has a cost.
 * @param {string} sCurrentUserEmail - The current user's email.
 * @returns {object} - Modal content for the confirm, success, and error phases.
 */
export const fnGetModalDetail = (
  aEventRegistrationsToCancel,
  bHasCost,
  sCurrentUserEmail
) => ({
  confirm: (
    <>
      <ul className='eventCancellationModal___eventList'>
        {aEventRegistrationsToCancel.map((oEvent) => (
          <li key={oEvent.sId}>{oEvent.oDetails.oEvent.TITLE}</li>
        ))}
      </ul>
      <p className='t-paragraph--small eventCancellationModal__message'>
        Please note that this cancellation applies to both the primary and all
        guests.
        {bHasCost &&
          ' To request a refund, please refer to the Refund Policy on the event details page.'}
      </p>
    </>
  ),
  success: (
    <>
      A confirmation email has been sent to <strong>{sCurrentUserEmail}</strong>
      .
      <br />
      <br />
      We&apos;re sorry that you can&apos;t join us, but we hope to see you at a
      future event.
    </>
  ),
  error: '',
});

/**
 * Initiates the cancellation of an event registration.
 *
 * @param {object} oEvent - The event object to cancel registration for.
 * @param {function} fnSetShowCancelRegistrationConfirmModal - Function to toggle the cancellation confirmation modal.
 * @param {object} oCurrentUserName - Object containing the current user's name details.
 * @param {string} sCurrentUserEmail - The current user's email.
 * @param {string} sCurrentUserLookupId - The current user's lookup ID.
 * @param {function} fnDispatch - The Redux dispatch function.
 */
export const fnBeginRegistrationCancellation = (
  oEvent,
  fnSetShowCancelRegistrationConfirmModal,
  fnDispatch
) => {
  const oReduxStore = store.getState();
  const oCurrentUserName = currentUserFirstLastNameSelector(oReduxStore);
  const sCurrentUserEmail = selectCurrentUserEmail(oReduxStore);
  const sCurrentUserLookupId = currentUserLookupIdSelector(oReduxStore);

  fnDispatch(
    CheckoutActions.update({
      fullName: oCurrentUserName.sName,
      firstName: oCurrentUserName.sPreferredName,
      lastName: oCurrentUserName.sLastName,
      email: sCurrentUserEmail,
      userLookupId: sCurrentUserLookupId,
    })
  );

  // Add the event registration cancellation to the cart
  fnAddEventRegistrationCancellationToCart(oEvent);

  // Show the cancellation confirmation modal
  fnSetShowCancelRegistrationConfirmModal(true);
};

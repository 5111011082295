import React, { useMemo, useState } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';

import PageLayout from 'components/Layout/PageLayout';
import LoadingZone from 'components/LoadingZone';
import usePageTitle from 'utils/hooks/usePageTitle';
import MultiLevelEventDetails from './MultiLevelEventDetails';
import IndividualEventDetails from './IndividualEventDetails';
import EventDetailsPageSkeleton from './EventDetailsPageSkeleton';
import EventDetailsPageBreadcrumbs from './EventDetailsPageBreadcrumbs';
import SignInOrContinueAsGuestModal from './SignInOrContinueAsGuestModal';
import SocialSignInModal from '../LandingPage/SocialSignInModal';
import EventDetailsPageError from './EventDetailsPageError';
import EventDetailsSignInRequired from './EventDetailsSignInRequired';

import useEventDetails from './hooks/useEventDetails';
import EventDetailsInviteOnly from './EventDetailsInviteOnly';
import { fnShowEventInviteOnly } from './helpers';
import EventDetailsContext from './EventDetailsContext';

const EventDetailsPage = ({ bIsAuthenticated }) => {
  const { eventSlug } = useParams();
  const { oEvent, sError, bIsEventDetailsLoading, fnRefreshEventDetails } =
    useEventDetails(eventSlug, bIsAuthenticated);

  const oMemoizedContext = useMemo(
    () => ({
      oEvent,
      sError,
      bIsEventDetailsLoading,
      fnRefreshEventDetails,
    }),
    [oEvent, sError, bIsEventDetailsLoading, fnRefreshEventDetails]
  );
  const [
    bShowSignInOrContinueAsGuestModal,
    fnSetShowSignInOrContinueAsGuestModal,
  ] = useState(false);
  const [bShowSignInModal, fnSetShowSignInModal] = useState(false);
  const [aChosenEvents, fnSetChosenEvents] = useState([]);

  const { REQUIRES_SIGN_IN } = oEvent?.REGISTRATION ?? {};

  usePageTitle(oEvent?.TITLE ?? '');

  const bIsMultiLevelEvent =
    oEvent?.IS_MLE_MAIN_EVENT === 1 || oEvent?.SUB_EVENTS?.length > 0;

  let contentToRender = null;
  switch (true) {
    case sError:
      contentToRender = <EventDetailsPageError />;
      break;
    case !bIsAuthenticated && REQUIRES_SIGN_IN === 1:
      contentToRender = (
        <EventDetailsSignInRequired
          oEvent={oEvent}
          fnSetShowSignInModal={fnSetShowSignInModal}
        />
      );
      break;
    case fnShowEventInviteOnly(oEvent, bIsAuthenticated):
      contentToRender = <EventDetailsInviteOnly oEvent={oEvent} />;
      break;
    default:
      contentToRender = bIsMultiLevelEvent ? (
        <MultiLevelEventDetails
          oEvent={oEvent}
          fnSetShowSignInOrContinueAsGuestModal={
            fnSetShowSignInOrContinueAsGuestModal
          }
          fnSetChosenEvents={fnSetChosenEvents}
        />
      ) : (
        <IndividualEventDetails
          oEvent={oEvent}
          fnSetShowSignInOrContinueAsGuestModal={
            fnSetShowSignInOrContinueAsGuestModal
          }
          fnSetChosenEvents={fnSetChosenEvents}
        />
      );
  }
  return (
    <PageLayout
      mTitle={<EventDetailsPageBreadcrumbs oEvent={oEvent} />}
      className='eventDetailsPage'
    >
      <LoadingZone
        isLoading={bIsEventDetailsLoading}
        skeleton={<EventDetailsPageSkeleton />}
      >
        <EventDetailsContext.Provider value={oMemoizedContext}>
          {contentToRender}
        </EventDetailsContext.Provider>
      </LoadingZone>
      {bShowSignInOrContinueAsGuestModal && (
        <SignInOrContinueAsGuestModal
          fnSetShowModal={fnSetShowSignInOrContinueAsGuestModal}
          fnSetShowSignInModal={fnSetShowSignInModal}
          aChosenEvents={aChosenEvents}
        />
      )}
      {bShowSignInModal && (
        <SocialSignInModal fnSetShowSignInModal={fnSetShowSignInModal} />
      )}
      {/* This tells <BrowserRouter /> where to render nested routes - here, the public profile modal */}
      <Outlet />
    </PageLayout>
  );
};

EventDetailsPage.propTypes = {
  bIsAuthenticated: PropTypes.bool.isRequired,
};

export default EventDetailsPage;

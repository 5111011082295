import React from 'react';
import { Link } from 'react-router-dom';

import LoadingZone from 'components/LoadingZone';
import { arrayCast } from 'utils/common';
import { useSelector } from 'react-redux';
import { selectFeatureFlags } from 'redux/selectors';
import Accordion from '../../Layout/Accordion';
import GivingHistory from './GivingHistory';
import GivingSummary from './GivingSummary';
import GivingSkeleton from './GivingSkeleton';
import Pledges from './Pledges';
import usePhilanthropyData from './usePhilanthropyData';
import TaxStatements from './TaxStatements';

const MyGiving = () => {
  const { bIsLoading, oPhilanthropyData } = usePhilanthropyData();
  const featureFlags = useSelector(selectFeatureFlags);
  const bHasPledges = oPhilanthropyData?.PLEDGES?.length > 0;
  const bHasHistory = oPhilanthropyData?.GIVING?.HISTORY_BY_YEAR?.length > 0;
  const bHas2024AthleticGiving = arrayCast(
    oPhilanthropyData?.GIVING?.SUMMARY?.TAX_YEARS_WITH_ATHLETIC_GIVING
  ).find((oYear) => oYear?.YEAR === '2024');
  const bShowTaxStatements =
    featureFlags?.enable_tax_statement_download &&
    !!oPhilanthropyData?.GIVING?.SUMMARY?.TAX_YEARS_WITH_ATHLETIC_GIVING &&
    bHas2024AthleticGiving;

  return (
    <Accordion
      sTitleIcon='bookClosed'
      mTitle='My Giving'
      bStartOpen
      sGtmCategory='My Giving'
    >
      <div className='myGiving__makeAGiftWrapper'>
        <Link className='myGiving__makeAGift button' to='/philanthropy/giving'>
          Make a gift
        </Link>
      </div>
      <LoadingZone
        isLoading={bIsLoading}
        skeleton={<GivingSkeleton />}
        message='Loading my giving'
      >
        <>
          <GivingSummary data={oPhilanthropyData?.GIVING?.SUMMARY} />
          {bHasPledges && <Pledges aPledgeData={oPhilanthropyData.PLEDGES} />}
          {bHasHistory && (
            <GivingHistory data={oPhilanthropyData?.GIVING?.HISTORY_BY_YEAR} />
          )}
          {bShowTaxStatements && (
            <TaxStatements
              taxYears={arrayCast(
                oPhilanthropyData?.GIVING?.SUMMARY
                  ?.TAX_YEARS_WITH_ATHLETIC_GIVING
              )}
            />
          )}
        </>
      </LoadingZone>
    </Accordion>
  );
};

export default MyGiving;

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { lookupIdMatcher } from 'utils/matchers';
import * as actions from '../../components/MyProfile/redux/actions';
import useActions from './useActions';
import useIsUserAuthenticated from './useIsUserAuthenticated';

let bHasFetchHappened = false;

/**
 * @description - Fetches profile data via Redux action
 * @param {string} sLookupId - Lookup ID of user to fetch profile data for
 *
 * @returns {bool} - If profile data is done loading or not
 */
const useProfileData = (sLookupId = 'self') => {
  const profileActions = useActions(actions);
  const profile = useSelector((state) => state.Profile);
  const [isLoading, setIsLoading] = useState(profile === null);
  const bIsUserAuthenticated = useIsUserAuthenticated();
  const bIsLookupIdValid =
    lookupIdMatcher.test(sLookupId) || sLookupId === 'self';

  useEffect(() => {
    if (
      bIsUserAuthenticated === true &&
      profile === null &&
      !bHasFetchHappened &&
      bIsLookupIdValid
    ) {
      bHasFetchHappened = true;
      const promise = new Promise((resolve, reject) => {
        profileActions.get({
          data: {
            lookupId: sLookupId,
            bIsUserAuthenticated,
          },
          resolve,
          reject,
        });
      });
      promise
        .then(() => {
          setIsLoading(false);
        })
        .catch((err) => console.error('Error loading profile data', err));
    } else if (profile || !bIsLookupIdValid) {
      setIsLoading(false);
    }
  }, [
    profile,
    profileActions,
    bIsUserAuthenticated,
    sLookupId,
    bIsLookupIdValid,
  ]);

  return isLoading;
};

export default useProfileData;

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';

import oWnApi from 'utils/WnApi';
import LoadingZone from 'components/LoadingZone';
import useIsUserAuthenticated from 'utils/hooks/useIsUserAuthenticated';
import usePageTitle from 'utils/hooks/usePageTitle';
import * as CheckoutActions from 'components/Checkout/redux/actions';
import { selectAllEventsInCart, selectEventsInCart } from '../redux/selectors';
import EventsYouRegisteredFor from './EventsYouRegisteredFor';
import AdditionalInfoConfirmation from './AdditionalInfoConfirmation';
import { fnFormatEventRegistrationDataForDad } from './helpers';
import { fnFormatEventRegConfirmationEmailData } from '../helpers';

const EventConfirmation = () => {
  const [bIsLoading, fnSetIsLoading] = useState(true);
  const sEmail = useSelector((oState) => oState.Checkout.email);
  const oCheckout = useSelector((oState) => oState.Checkout);
  const iTotalCost = useSelector((oState) => oState.Checkout.checkoutTotal);
  const aEventsInCart = useSelector(selectAllEventsInCart);
  const [oSearchParams] = useSearchParams();
  const bIsUserAuthenticated = useIsUserAuthenticated();
  const sPaymentTransactionId = oSearchParams.get('transid');
  const fnDispatch = useDispatch();

  let sConfirmationMsg = '';
  if (aEventsInCart.length > 0) {
    sConfirmationMsg =
      aEventsInCart[0]?.oDetails?.oEvent?.REGISTRATION.CONFIRMATION_MESSAGE;
  }

  const oEventContact = useMemo(() => {
    if (aEventsInCart.length > 0) {
      // Use the main event contact if the user registered for a sub-event
      return aEventsInCart[0].oDetails.oEvent.MAIN_EVENT
        ? aEventsInCart[0].oDetails.oEvent.MAIN_EVENT.CONTACT
        : aEventsInCart[0].oDetails.oEvent.CONTACT;
    }
    return {};
  }, [aEventsInCart]);

  const oRegistrationDataForConfirmationEmail = useMemo(() => {
    if (iTotalCost === 0) {
      return null;
    }
    const oRegData = fnFormatEventRegistrationDataForDad(aEventsInCart);
    const oConfirmationEmailData = fnFormatEventRegConfirmationEmailData(
      oCheckout,
      aEventsInCart
    );
    return {
      ...oRegData,
      ...oConfirmationEmailData,
    };
  }, [iTotalCost, aEventsInCart, oCheckout]);

  // Finalize registration for paid events
  useEffect(() => {
    if (iTotalCost === 0) {
      fnSetIsLoading(false);
    } else if (iTotalCost > 0 && bIsUserAuthenticated !== null) {
      let sTransactionTotal = oSearchParams.get('transaction_total');
      sTransactionTotal = sTransactionTotal.replace(/,/g, ''); // Remove all commas. Commas interfere with parseFloat.
      const iPaymentTransactionTotal = parseFloat(sTransactionTotal);
      if (iPaymentTransactionTotal !== iTotalCost) {
        throw new Error(
          `The payment made (${iPaymentTransactionTotal}) does not match the registration's total cost (${iTotalCost}).`
        );
      }

      if (!sPaymentTransactionId) {
        throw new Error('Missing payment transaction ID');
      }

      const sEncodedDadTransactionId = oSearchParams.get('dad_transaction_id');
      if (!sEncodedDadTransactionId) {
        throw new Error('Missing DAD transaction ID');
      }

      oWnApi
        .patch(
          'events/registrations',
          {
            ...oRegistrationDataForConfirmationEmail,
            paymentTransactionId: sPaymentTransactionId,
            dadTransactionId: sEncodedDadTransactionId,
            paymentAmount: iPaymentTransactionTotal,
          },
          bIsUserAuthenticated
        )
        .then((oResponse) => {
          if (oResponse.status === 200) {
            console.log('oResponse', oResponse);
            if (oResponse.data.maskedConfirmationEmail) {
              fnDispatch(
                CheckoutActions.update({
                  email: oResponse.data.maskedConfirmationEmail,
                })
              );
            }
          }
          if (oResponse.status !== 200) {
            throw new Error('Failed to finalize paid event registration');
          }
          fnSetIsLoading(false);
        });
    }
  }, [
    iTotalCost,
    oSearchParams,
    sPaymentTransactionId,
    bIsUserAuthenticated,
    oRegistrationDataForConfirmationEmail,
    fnDispatch,
  ]);

  usePageTitle('Event Registration - Confirmation');

  return (
    <LoadingZone isLoading={bIsLoading}>
      <div className='eventConfirmation'>
        <div
          className='eventConfirmation__message t-paragraph--large'
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: sConfirmationMsg,
          }}
        />
        <p className='eventConfirmation__email'>
          {sEmail ? (
            <span>
              We sent your registration confirmation to{' '}
              <strong data-cy='email-address'>{sEmail}</strong>.
            </span>
          ) : (
            'Please print or save this confirmation page for your records.'
          )}
        </p>
        {iTotalCost > 0 && (
          <p
            className='eventConfirmation__transactionNumber t-paragraph--small'
            data-cy='confirmation-transaction-confirmation'
          >
            Transaction Confirmation: #
            <span data-cy='confirmation-transaction-id'>
              {sPaymentTransactionId}
            </span>
          </p>
        )}
        <EventsYouRegisteredFor />
        <AdditionalInfoConfirmation />
        <p className='eventConfirmation__contact'>
          If you have questions about your registration, please contact{' '}
          {oEventContact.NAME} at{' '}
          <a href={`mailto:${oEventContact.EMAIL}`}>{oEventContact.EMAIL}</a>.
        </p>
        <Link to='/main' className='eventConfirmation__backToHome button'>
          Return to home page
        </Link>
      </div>
    </LoadingZone>
  );
};

export default EventConfirmation;

import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import useWindowSize from 'utils/hooks/useWindowSize';
import useIsUserAuthenticated from 'utils/hooks/useIsUserAuthenticated';
import { selectAllEventsInCart } from '../../redux/selectors';
import ConditionalNavLink from './ConditionalNavLink';
import { selectEventRegNavSteps } from './redux/selectors';

// Displays links to each event in the cart
const EventRegistrationSubNav = ({ iMaxStepVisitedIndex }) => {
  const oLocation = useLocation();
  const { sScreenSize } = useWindowSize();
  const aEventsInCart = useSelector(selectAllEventsInCart);
  const bIsAuthenticated = useIsUserAuthenticated();
  const aAllEventRegistrationSteps = useSelector((state) =>
    selectEventRegNavSteps(state, bIsAuthenticated)
  );

  // Only show the submenu on medium or larger screens
  return sScreenSize !== 'small' ? (
    <ul className='eventRegistrationNav__secondaryList'>
      {aEventsInCart.map((oEventCartItem) => {
        const { SLUG, TITLE } = oEventCartItem.oDetails.oEvent;
        const sEventLink =
          aAllEventRegistrationSteps.find((sStepUrl) =>
            sStepUrl.includes(SLUG)
          ) || '';
        const iEventLinkIndex = aAllEventRegistrationSteps.indexOf(sEventLink);
        const bIsCurrentEvent = oLocation.pathname.includes(SLUG);
        const bIsClickable =
          !!sEventLink && iMaxStepVisitedIndex >= iEventLinkIndex;
        return (
          <li key={oEventCartItem.sId}>
            <ConditionalNavLink
              sTo={sEventLink}
              bCondition={bIsClickable}
              className={`eventRegistrationNav__secondaryLink t-paragraph--small ${
                bIsCurrentEvent ? 'active' : ''
              }`}
            >
              {TITLE}
            </ConditionalNavLink>
          </li>
        );
      })}
    </ul>
  ) : null;
};

EventRegistrationSubNav.propTypes = {
  iMaxStepVisitedIndex: PropTypes.number.isRequired,
};

export default EventRegistrationSubNav;

import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Button from 'components/Buttons/Button';
import { oCurrencyFormatter } from 'utils/common';
import useIsUserAuthenticated from 'utils/hooks/useIsUserAuthenticated';
import { selectNextEventRegStep } from './EventRegistrationNavBar/redux/selectors';
import EventRegistrationContext from '../EventRegistrationContext';
import useCurrentEventCartItem from '../useCurrentEventCartItem';
import { fnGetEventStatus } from './helpers';

const EventRegistrationFooter = ({ fnSetNonRegistrableEventStatuses }) => {
  const oLocation = useLocation();
  const fnNavigate = useNavigate();

  const { sCurrentFormId, fnSetCurrentFormId, bIsSubmittingCurrentForm } =
    useContext(EventRegistrationContext);
  const iTotalCost = useSelector((state) => state.Checkout.checkoutTotal);
  const sCheckoutUserFullName = useSelector((state) => state.Checkout.fullName);
  const [bIsNextButtonDisabled, fnSetIsNextButtonDisabled] = useState(false);
  const { eventSlug } = useParams();
  const oCurrentEventInCart = useCurrentEventCartItem();
  const bIsUserAuthenticated = useIsUserAuthenticated();
  const sNextStepUrl = useSelector((state) =>
    selectNextEventRegStep(
      state,
      window.location.pathname,
      bIsUserAuthenticated
    )
  );

  // Matches `/events/register/review`, `/events/register/additional-info`, and `/events/register/contact`
  const bShowTotalCost =
    /^\/events\/register\/(review|additional-info|contact)$/.test(
      oLocation.pathname
    );
  let sNextButtonLabel = 'Next';
  const bIsFinalReviewPage = /^\/events\/register\/review$/.test(
    oLocation.pathname
  );
  if (bIsFinalReviewPage) {
    sNextButtonLabel =
      iTotalCost > 0 ? 'Proceed to payment' : 'Submit registration';
  }

  const bIsUserIdentificationPage =
    /^\/events\/register\/user-identification$/.test(oLocation.pathname);

  // Disable the Next button on the user identification page if the user hasn't been selected
  useEffect(() => {
    if (bIsUserIdentificationPage && !sCheckoutUserFullName) {
      fnSetIsNextButtonDisabled(true);
    } else {
      fnSetIsNextButtonDisabled(false);
    }
  }, [oLocation.pathname, sCheckoutUserFullName, bIsUserIdentificationPage]);

  // Disable the Next button on the guest management page if there are 0 registrants
  useEffect(() => {
    const bIsGuestManagementPage = /^\/events\/.*\/register\/guests$/.test(
      oLocation.pathname
    );
    if (bIsGuestManagementPage) {
      if (oCurrentEventInCart?.oDetails?.aGuests.length === 0) {
        fnSetIsNextButtonDisabled(true);
      } else {
        fnSetIsNextButtonDisabled(false);
      }
    }
  }, [oLocation.pathname, oCurrentEventInCart?.oDetails?.aGuests?.length]);

  const fnHandleNextButtonClick = async () => {
    // Immediately advance to the next step, so that the user doesn't have to wait for the event status API call to finish
    fnNavigate(sNextStepUrl);

    // Check if the event is registrable
    if (eventSlug) {
      const oEventStatus = await fnGetEventStatus(
        eventSlug,
        oCurrentEventInCart?.oDetails?.aGuests || [],
        bIsUserAuthenticated
      );

      if (!oEventStatus.IS_EVENT_REGISTRABLE) {
        fnSetNonRegistrableEventStatuses((prevValue) => [
          ...prevValue,
          oEventStatus,
        ]);
      }
    }
  };

  return (
    <footer className='eventRegistrationFooter'>
      {bShowTotalCost && (
        <p className='eventRegistrationFooter__totalCost t-paragraph--bold'>
          Total Cost: {oCurrencyFormatter.format(iTotalCost)}
        </p>
      )}
      <div className='eventRegistrationFooter__buttons'>
        {sCurrentFormId ? (
          <button
            type='submit'
            form={sCurrentFormId}
            className='eventRegistrationFooter__next'
            disabled={bIsSubmittingCurrentForm}
            data-cy='next-button'
          >
            {sNextButtonLabel}
          </button>
        ) : (
          <Button
            className='eventRegistrationFooter__next'
            fnHandleClick={fnHandleNextButtonClick}
            bIsDisabled={bIsNextButtonDisabled}
            sCypressId='next-button'
          >
            {sNextButtonLabel}
          </Button>
        )}

        <Button
          sFlavor='secondary'
          fnHandleClick={() => {
            fnSetCurrentFormId(null);
            fnNavigate(-1);
          }}
          sCypressId='back-button'
        >
          Back
        </Button>
      </div>
    </footer>
  );
};

EventRegistrationFooter.propTypes = {
  fnSetNonRegistrableEventStatuses: PropTypes.func.isRequired,
};

export default EventRegistrationFooter;

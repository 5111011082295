import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import oIconLibrary from 'utils/iconLibrary';
import { fnFormatEventTime } from 'components/Events/helpers';
import ComponentErrorBoundary from 'components/ErrorBoundaries/ComponentErrorBoundary';
import EventAttendeesList from './EventAttendeesList';
import MyParty from './MyParty';
import EventContact from './EventContact';
import EventLocation from '../EventLocation';
import AddToCalendar from '../AddToCalendar';
import EventDescription from '../EventDescription';
import EventCost from '../EventCost';
import IndividualEventDetailsFooter from './IndividualEventDetailsFooter';
import EventDetailsPageHeader from '../EventDetailsPageHeader';
import EventRegistrationWindowClose from '../EventRegistrationWindowClose';
import EventDetailsContext from '../EventDetailsContext';

const IndividualEventDetails = ({
  fnSetShowSignInOrContinueAsGuestModal,
  fnSetChosenEvents,
}) => {
  const { oEvent } = useContext(EventDetailsContext);
  const bIsRegistered = oEvent?.REGISTRATION?.IS_REGISTERED === 1;
  const bIsWaitlisted = [true, 1].includes(oEvent?.REGISTRATION?.IS_WAITLISTED);
  return oEvent ? (
    <>
      <EventDetailsPageHeader oEvent={oEvent} />
      <div>
        <div className='eventDetails__sectionWithLine'>
          <p className='eventDetails__time'>
            <FontAwesomeIcon icon={oIconLibrary.fontAwesome.clock} />{' '}
            {fnFormatEventTime(
              oEvent?.START_DATE_TIME,
              oEvent?.END_DATE_TIME,
              oEvent?.TIME_ZONE
            )}
          </p>
          {oEvent?.LOCATION && <EventLocation oLocation={oEvent?.LOCATION} />}
          <EventCost oEvent={oEvent} sClassName='eventDetails__cost' />
          <EventRegistrationWindowClose
            registrationCloseDateTime={oEvent?.REGISTRATION?.CLOSE_DATETIME}
            timeZone={oEvent?.TIME_ZONE}
          />
        </div>
        {(bIsRegistered || bIsWaitlisted) && (
          <MyParty oParty={oEvent?.MY_PARTY} bIsWaitlisted={bIsWaitlisted} />
        )}
        {oEvent?.DESCRIPTION && (
          <EventDescription sDescription={oEvent.DESCRIPTION} />
        )}
        {oEvent?.CONTACT && <EventContact oContact={oEvent?.CONTACT} />}
        {oEvent?.ATTENDEES && (
          <EventAttendeesList aAttendees={oEvent?.ATTENDEES} />
        )}
        {bIsRegistered && (
          <ComponentErrorBoundary sErrorMessage='Something went wrong with the Add to Calendar button'>
            <AddToCalendar oEvent={oEvent} />
          </ComponentErrorBoundary>
        )}
        <IndividualEventDetailsFooter
          oEvent={oEvent}
          fnSetShowSignInOrContinueAsGuestModal={
            fnSetShowSignInOrContinueAsGuestModal
          }
          fnSetChosenEvents={fnSetChosenEvents}
        />
      </div>
    </>
  ) : null;
};

IndividualEventDetails.propTypes = {
  oEvent: PropTypes.shape({
    DESCRIPTION: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    CONTACT: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    ATTENDEES: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    MY_PARTY: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    LOCATION: PropTypes.object,
    REGISTRATION: PropTypes.shape({
      HAS_COST: PropTypes.number,
      IS_REGISTERED: PropTypes.number,
      IS_WAITLISTED: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    }),
    START_DATE_TIME: PropTypes.string,
    END_DATE_TIME: PropTypes.string,
    TIME_ZONE: PropTypes.string,
  }),
  fnSetShowSignInOrContinueAsGuestModal: PropTypes.func.isRequired,
  fnSetChosenEvents: PropTypes.func.isRequired,
};

IndividualEventDetails.defaultProps = {
  oEvent: null,
};

export default IndividualEventDetails;

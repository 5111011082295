import React from 'react';
import { useSelector } from 'react-redux';

import HiddenFieldsForm from 'components/Checkout/CheckoutReview/HiddenFieldsForm';
import { fnElementRefPropType } from 'utils/customPropTypes';
import { getWakeNetworkHomeUrl } from 'config';
import useIsUserAuthenticated from 'utils/hooks/useIsUserAuthenticated';
import { selectEventsInCart } from '../redux/selectors';
import { selectEventRegNavSteps } from '../EventRegistrationContainer/EventRegistrationNavBar/redux/selectors';

const PaymentHiddenForm = ({ oHiddenFieldFormRef }) => {
  const oCheckoutData = useSelector((state) => state.Checkout);
  const sEnvironment = process.env.REACT_APP_STAGE;
  const aEventCartItems = useSelector(selectEventsInCart);
  const bIsUserAuthenticated = useIsUserAuthenticated();
  const aAllEventRegNavSteps = useSelector((state) =>
    selectEventRegNavSteps(state, bIsUserAuthenticated)
  );
  const sFirstEventRegNavStep = aAllEventRegNavSteps[0];
  const sFirstEventRegNavStepWithoutSlash = sFirstEventRegNavStep.substring(1);
  const sEventNavUrl = `${getWakeNetworkHomeUrl()}${sFirstEventRegNavStepWithoutSlash}`;
  const sRefundPolicyLink =
    aEventCartItems[0]?.oDetails?.oEvent?.REGISTRATION?.REFUND_LINK || '';

  const sConfirmationUrl = `${getWakeNetworkHomeUrl()}events/register/confirmation`;

  const sEventNameForPaymentReport =
    aEventCartItems?.length > 1
      ? aEventCartItems[0]?.oDetails?.oEvent?.MAIN_EVENT?.DAD_TITLE
      : aEventCartItems[0]?.oDetails?.oEvent?.DAD_TITLE;

  return (
    <HiddenFieldsForm
      oHiddenFieldsFormData={{
        contact: {
          first_name: oCheckoutData.firstName,
          last_name: oCheckoutData.lastName,
          email_address:
            sEnvironment === 'prod'
              ? oCheckoutData.email
              : 'ait-developers@wfu.edu',
        },
        budget_code:
          aEventCartItems[0]?.oDetails?.oEvent?.REGISTRATION.BUDGET_CODE,
        registration_amount: oCheckoutData.checkoutTotal,
        refund_policy_link: sRefundPolicyLink,
        events_nav_link_url: sEventNavUrl,
        event_name: sEventNameForPaymentReport,
      }}
      oHiddenFieldFormRef={oHiddenFieldFormRef}
      sPaymentFormUrl={`https://${
        sEnvironment !== 'prod' ? `${sEnvironment}.` : ''
      }registration.secure.wfu.edu/wakenetwork-registration/payment`}
      sReturnPageUrl={sConfirmationUrl}
    />
  );
};

PaymentHiddenForm.propTypes = {
  oHiddenFieldFormRef: fnElementRefPropType.isRequired,
};

export default PaymentHiddenForm;

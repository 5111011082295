import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import useIsUserAuthenticated from 'utils/hooks/useIsUserAuthenticated';
import { selectEventRegNavSteps } from './redux/selectors';
import ConditionalNavLink from './ConditionalNavLink';
import EventRegistrationSubNav from './EventRegistrationSubNav';
import ReviewRegistrationSubNav from './ReviewRegistrationSubNav';

const EventRegistrationNavBar = () => {
  const oLocation = useLocation();
  const bIsAuthenticated = useIsUserAuthenticated();
  const aAllEventRegistrationSteps = useSelector((state) =>
    selectEventRegNavSteps(state, bIsAuthenticated)
  );
  const [iMaxStepVisitedIndex, fnSetMaxStepVisitedIndex] = useState(0);
  // If the URL matches `/events/some-event-slug/register`, then the Events link should be active
  const bIsEventsLinkActive = /\/events\/[a-zA-Z0-9-]+\/register/.test(
    oLocation.pathname
  );
  // Matches `/events/register/review`, `/events/register/additional-info`, and `/events/register/contact`
  const bIsReviewLinkActive =
    /^\/events\/register\/(review|additional-info|contact)$/.test(
      oLocation.pathname
    );

  // Update the max step visited index when the URL changes
  useEffect(() => {
    const iCurrentEventStepIndex = aAllEventRegistrationSteps.indexOf(
      oLocation.pathname
    );
    fnSetMaxStepVisitedIndex((iPreviousMaxStepIndex) =>
      Math.max(iPreviousMaxStepIndex, iCurrentEventStepIndex)
    );
  }, [oLocation.pathname, aAllEventRegistrationSteps]);

  return aAllEventRegistrationSteps.length > 0 ? (
    <nav className='eventRegistrationNav' aria-labelledby='event-reg-nav'>
      <p id='event-reg-nav' className='hide_accessibly'>
        Event registration navigation
      </p>
      <ol className='eventRegistrationNav__primaryList'>
        <li className='eventRegistrationNav__primaryListItem'>
          <ConditionalNavLink
            sTo={aAllEventRegistrationSteps[0]}
            bCondition={
              iMaxStepVisitedIndex !== aAllEventRegistrationSteps.length - 1
            }
            className={`eventRegistrationNav__primaryLink t-paragraph--small ${
              bIsEventsLinkActive ? 'active' : ''
            }`}
          >
            Events
          </ConditionalNavLink>
          {bIsEventsLinkActive && (
            <EventRegistrationSubNav
              iMaxStepVisitedIndex={iMaxStepVisitedIndex}
            />
          )}
        </li>
        <li>
          <ConditionalNavLink
            sTo='/events/register/additional-info'
            bCondition={
              iMaxStepVisitedIndex >=
                aAllEventRegistrationSteps.indexOf(
                  '/events/register/additional-info'
                ) &&
              iMaxStepVisitedIndex !== aAllEventRegistrationSteps.length - 1
            }
            className={`eventRegistrationNav__primaryLink t-paragraph--small ${
              bIsReviewLinkActive ? 'active' : ''
            }`}
          >
            Review
          </ConditionalNavLink>
          {bIsReviewLinkActive && (
            <ReviewRegistrationSubNav
              iMaxStepVisitedIndex={iMaxStepVisitedIndex}
            />
          )}
        </li>
        {aAllEventRegistrationSteps.includes('/events/register/payment') && (
          <li>
            <ConditionalNavLink
              sTo='/events/register/payment'
              bCondition={
                iMaxStepVisitedIndex >=
                  aAllEventRegistrationSteps.indexOf(
                    '/events/register/payment'
                  ) &&
                iMaxStepVisitedIndex !== aAllEventRegistrationSteps.length - 1
              }
              className='eventRegistrationNav__primaryLink t-paragraph--small'
            >
              Payment
            </ConditionalNavLink>
          </li>
        )}
        <li>
          <ConditionalNavLink
            sTo='/events/register/confirmation'
            bCondition={
              iMaxStepVisitedIndex >=
              aAllEventRegistrationSteps.indexOf(
                '/events/register/confirmation'
              )
            }
            className='eventRegistrationNav__primaryLink t-paragraph--small'
          >
            Confirmation
          </ConditionalNavLink>
        </li>
      </ol>
    </nav>
  ) : null;
};

export default EventRegistrationNavBar;

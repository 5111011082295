import React from 'react';
import { useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { selectNextEventRegStep } from 'components/EventRegistration/EventRegistrationContainer/EventRegistrationNavBar/redux/selectors';
import usePageTitle from 'utils/hooks/usePageTitle';
import ButtonWithIcon from 'components/Buttons/ButtonWithIcon';
import useIsUserAuthenticated from 'utils/hooks/useIsUserAuthenticated';
import useCurrentEventCartItem from '../useCurrentEventCartItem';
import { fnRemoveEventFromCart } from '../redux/eventHelpers';

const EventWaitlist = () => {
  const fnNavigate = useNavigate();
  const oEventCartItem = useCurrentEventCartItem();
  const bIsUserAuthenticated = useIsUserAuthenticated();
  const sNextStepUrl = useSelector((state) =>
    selectNextEventRegStep(
      state,
      window.location.pathname,
      bIsUserAuthenticated
    )
  );

  const fnHandleRemoveEvent = () => {
    fnRemoveEventFromCart(oEventCartItem.sId);
    fnNavigate(-1);
  };

  usePageTitle('Event Registration - Waitlist');

  if (!oEventCartItem) {
    return null;
  }
  return (
    <div className='guestManagement'>
      <h1 className='guestManagement__question t-paragraph--medium'>
        {oEventCartItem.oDetails.oEvent.TITLE} is at capacity. Would you like to
        join the waitlist?
      </h1>
      <div className='eventReg__yesNoButtons'>
        <ButtonWithIcon
          sIcon='check'
          className='eventReg__yesNoButton'
          fnHandleClick={() => {
            fnNavigate(sNextStepUrl);
          }}
          sCypressId='yes-waitlist-button'
        >
          Yes
        </ButtonWithIcon>
        <ButtonWithIcon
          sIcon='close'
          className='eventReg__yesNoButton'
          fnHandleClick={fnHandleRemoveEvent}
          sCypressId='no-waitlist-button'
        >
          No
        </ButtonWithIcon>
      </div>
    </div>
  );
};

export default EventWaitlist;

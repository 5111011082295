import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import oWnApi from 'utils/WnApi';
import * as CheckoutActions from 'components/Checkout/redux/actions';
import { selectEventsToCancel } from 'components/EventRegistration/redux/selectors';
import { fnFormatEventRegistrationDataForDad } from 'components/EventRegistration/EventConfirmation/helpers';
import { fnFormatEventRegConfirmationEmailData } from 'components/EventRegistration/helpers';
import useIsUserAuthenticated from 'utils/hooks/useIsUserAuthenticated';
import EventDetailsContext from '../EventDetailsContext';

/**
 * Handles event registration cancellations, including API calls, state updates,
 * and refreshing event details.
 *
 * @param {function} fnRefreshEventDetails - A function to refresh event details after successful cancellation.
 * @returns {object} An object containing:
 *   - bIsProcessingCancellation: A boolean indicating if the cancellation is in progress.
 *   - sCancellationPhase: A string representing the current phase of cancellation ('confirm', 'success', 'error').
 *   - fnCancelRegistration: A function to initiate the cancellation process.
 *   - aEventRegistrationsToCancel: An array of event registrations to be canceled.
 */
const useCancelEventRegistration = () => {
  const { fnRefreshEventDetails } = useContext(EventDetailsContext);
  const [bIsProcessingCancellation, fnSetIsProcessingCancellation] =
    useState(false);
  const [sCancellationPhase, fnSetCancellationPhase] = useState('confirm');
  const aEventRegistrationsToCancel = useSelector(selectEventsToCancel);
  const fnDispatch = useDispatch();
  const oCheckout = useSelector((state) => state.Checkout);
  const bIsUserAuthenticated = useIsUserAuthenticated();

  const fnCancelRegistration = async () => {
    const oRegistrationCancellationData = fnFormatEventRegistrationDataForDad(
      aEventRegistrationsToCancel
    );
    const oConfirmationEmailData = fnFormatEventRegConfirmationEmailData(
      oCheckout,
      aEventRegistrationsToCancel
    );

    const oRegistrationCancellationDataWithExtraSeasoning = {
      ...oRegistrationCancellationData,
      ...oConfirmationEmailData,
    };
    fnSetIsProcessingCancellation(true);
    const oResponse = await oWnApi.delete(
      'events/registrations',
      {
        ...oRegistrationCancellationDataWithExtraSeasoning,
      },
      bIsUserAuthenticated
    );
    if (oResponse.status === 200) {
      fnSetCancellationPhase('success');
      fnDispatch(CheckoutActions.reset({}));
      fnRefreshEventDetails();
    } else {
      console.error('Failed to cancel registration for event');
      fnSetCancellationPhase('error');
    }
    fnSetIsProcessingCancellation(false);
  };

  return {
    bIsProcessingCancellation,
    sCancellationPhase,
    fnCancelRegistration,
    aEventRegistrationsToCancel,
  };
};

export default useCancelEventRegistration; // Default export

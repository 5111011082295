import PropTypes from 'prop-types';

/**
 * Calculates the total cost for an event.
 *
 * @param {object} oEventCartItem - Event from the Redux store's cart.
 * @returns {number|string} - The total cost of the event.
 */
export const fnCalculateEventCost = (oEventCartItem) => {
  const { aGuests } = oEventCartItem.oDetails;
  const nEventCost = aGuests.reduce((nTotal, oGuest) => {
    const nGuestCost = oGuest.REG_OPTION?.COST ?? 0;
    return nTotal + nGuestCost;
  }, 0);
  return nEventCost;
};

/**
 * Prop type definition for event cart item.
 */
export const eventCartItemPropType = PropTypes.shape({
  sId: PropTypes.string.isRequired,
  oDetails: PropTypes.shape({
    oEvent: PropTypes.shape({
      TITLE: PropTypes.string.isRequired,
      REGISTRATION: PropTypes.shape({
        REGISTRATION_OPTIONS: PropTypes.arrayOf(
          PropTypes.shape({
            COST: PropTypes.number.isRequired,
          })
        ).isRequired,
      }).isRequired,
    }).isRequired,
    aGuests: PropTypes.arrayOf(
      PropTypes.shape({
        REG_OPTION: PropTypes.shape({
          COST: PropTypes.number.isRequired,
        }),
      })
    ).isRequired,
  }).isRequired,
});

/**
 * Calculates the maximum number of registrants allowed to register for an event.
 * Essentially, it's the max number of guests plus the registrant, unless the event capacity is reached.
 *
 * @param {number} iEventCapacity - Event capacity
 * @param {number} iTotalRegistrations - Total number of registrations
 * @param {number} iMaxGuests - Maximum number of guests allowed per registrant
 * @returns {number} - The maximum number of registrants allowed for the event.
 */
export const fnCalculateMaxNumberOfRegistrantsForEvent = (
  iEventCapacity,
  iTotalRegistrations,
  iMaxGuests
) => {
  if (iMaxGuests === 0) {
    return 1;
  }

  const iMaxGuestsPlusRegistrant = iMaxGuests + 1;
  if (iEventCapacity === 0) {
    return iMaxGuestsPlusRegistrant;
  }

  const iRemainingCapacity = iEventCapacity - iTotalRegistrations;
  if (iRemainingCapacity < iMaxGuestsPlusRegistrant) {
    return iRemainingCapacity;
  }
  return iMaxGuestsPlusRegistrant;
};

/**
 * Formats the event registration confirmation email data.
 *
 * @param {object} oCheckout - Redux store Checkout state.
 * @param {array} aEventCartItems - Array of event cart items.
 * @returns {object} - The formatted event registration confirmation email data.
 */
export const fnFormatEventRegConfirmationEmailData = (
  oCheckout,
  aEventCartItems
) => {
  let oResult = {};
  const { email, firstName, lastName, userLookupId } = oCheckout;
  const oUserInformation = {
    email,
    firstName,
    lastName,
    lookupId: userLookupId,
  };
  const oFirstEventInCartDetails = aEventCartItems[0].oDetails.oEvent;
  const sConfirmationMessage =
    oFirstEventInCartDetails.REGISTRATION.CONFIRMATION_MESSAGE;
  // Use the main event contact if the user registered for a sub-event
  const oEventContact =
    oFirstEventInCartDetails.MAIN_EVENT?.CONTACT ??
    oFirstEventInCartDetails.CONTACT;

  oResult = {
    oUserInformation,
    sConfirmationMessage,
    oEventContact,
  };
  return oResult;
};

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Modal from 'components/Layout/Modal/Modal';
import Button from 'components/Buttons/Button';
import LoadingZone from 'components/LoadingZone';

import { selectCurrentUserEmail } from 'redux/selectors';
import * as CheckoutActions from 'components/Checkout/redux/actions';
import useCancelEventRegistration from './useCancelEventRegistration';
import EventDetailsContext from '../EventDetailsContext';
import { oModalMessages, fnGetModalDetail } from './helpers';

const CancelEventRegistrationModal = ({
  bShowCancelRegistrationConfirmModal,
  fnSetShowCancelRegistrationConfirmModal,
}) => {
  const { fnRefreshEventDetails } = useContext(EventDetailsContext);
  const {
    bIsProcessingCancellation,
    sCancellationPhase,
    fnCancelRegistration,
    aEventRegistrationsToCancel,
  } = useCancelEventRegistration();
  const bHasCost = aEventRegistrationsToCancel.some(
    (oEvent) => oEvent.oDetails.oEvent.COST > 0
  );
  const sCurrentUserEmail = useSelector(selectCurrentUserEmail);
  const fnDispatch = useDispatch();

  const fnToggleModal = () => {
    if (bShowCancelRegistrationConfirmModal) {
      fnDispatch(CheckoutActions.reset({}));
    }
    if (sCancellationPhase === 'success') {
      fnDispatch(CheckoutActions.reset({}));
      fnRefreshEventDetails();
    }
    fnSetShowCancelRegistrationConfirmModal((prevState) => !prevState);
  };

  return (
    <Modal
      sFlavor={sCancellationPhase === 'success' ? 'message' : 'alert'}
      sModalSubtitle={oModalMessages[sCancellationPhase]}
      sModalTitle='Cancel Event Registration'
      sModalDetail={
        fnGetModalDetail(
          aEventRegistrationsToCancel,
          bHasCost,
          sCurrentUserEmail
        )[sCancellationPhase]
      }
      fnSetShowModal={fnToggleModal}
      sCypressId='cancel-registration-modal'
    >
      {sCancellationPhase === 'confirm' && (
        <LoadingZone isLoading={bIsProcessingCancellation}>
          <div className='eventCancellationModal__backConfirm'>
            <Button
              fnHandleClick={() => fnToggleModal()}
              sFlavor='secondary'
              sCypressId='cancel-registration-back-button'
            >
              Back
            </Button>
            <Button
              fnHandleClick={fnCancelRegistration}
              sCypressId='cancel-registration-confirm-button'
            >
              Confirm Cancellation
            </Button>
          </div>
        </LoadingZone>
      )}
      {sCancellationPhase === 'success' && (
        <Button
          fnHandleClick={() => fnToggleModal()}
          sFlavor='secondary'
          sCypressId='cancel-registration-success-close-button'
        >
          Continue
        </Button>
      )}
      {sCancellationPhase === 'error' && (
        <Button
          fnHandleClick={() => fnSetShowCancelRegistrationConfirmModal(false)}
          sFlavor='secondary'
        >
          Close
        </Button>
      )}
    </Modal>
  );
};

CancelEventRegistrationModal.propTypes = {
  bShowCancelRegistrationConfirmModal: PropTypes.bool.isRequired,
  fnSetShowCancelRegistrationConfirmModal: PropTypes.func.isRequired,
};

export default CancelEventRegistrationModal;

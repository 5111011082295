import React, { useMemo } from 'react';
import { Form, Formik } from 'formik';

import { Select } from 'components/Form/Fields';
import { SubmitButton } from 'components/Form/Buttons';
import {
  buildTaxStatementFormInitialValues,
  buildTaxStatementFormSchema,
  buildTaxYearOptions,
} from './helpers';
import { TaxYear } from './types';
import TaxStatementPleaseHoldMsg from './TaxStatementPleaseHoldMsg';

type TaxStatementFormProps = {
  taxYears?: TaxYear[];
  handleSubmit: (formValues: { year: string }) => void;
};

const TaxStatementForm: React.FC<TaxStatementFormProps> = ({
  taxYears = [],
  handleSubmit,
}) => {
  const options = useMemo(
    () => buildTaxYearOptions([{ YEAR: '2024' }]), // Hardcoded temporarily to 2024 due to missing historical Deacon Club levels
    [taxYears]
  );
  const initialValues = useMemo(
    () => buildTaxStatementFormInitialValues(taxYears),
    [taxYears]
  );
  const schema = useMemo(
    () => buildTaxStatementFormSchema(taxYears),
    [taxYears]
  );
  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={schema}
    >
      {(formikProps) => (
        <Form>
          <Select
            label='Calendar Year'
            id='tax-year'
            name='year'
            valueKey='value'
            options={options}
            className='taxStatements__select'
          />
          <SubmitButton bIsCentered>Generate Tax Documents</SubmitButton>
          <TaxStatementPleaseHoldMsg />
        </Form>
      )}
    </Formik>
  );
};

export default TaxStatementForm;

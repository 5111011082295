import * as R from 'ramda';

import { fnFormatSubEventDateAndTime } from 'components/EventDetailsPage/helpers';
import { fnBuildGoogleCalendarLink } from 'components/EventDetailsPage/AddToCalendar/helpers';

/**
 * Combines the guest's dietary restrictions and additional info into a single string.
 *
 * @description - Combines the guest's special needs into this format:
 *    'Vegetarian, No Garlic, Other: allergic to Greek yogurt; I need an aisle seat'
 *
 * @param {Object} oAdditionalInfo - Guest's dietary restrictions and additional info
 * @returns {string} - Combined dietary restrictions and additional info
 */
export const fnCombineGuestSpecialNeeds = (oAdditionalInfo) => {
  const {
    DIETARY_RESTRICTIONS,
    OTHER_DIETARY_RESTRICTION,
    ADDITIONAL_INFO_CONTENT,
  } = oAdditionalInfo;
  const aCombined = [];

  if (DIETARY_RESTRICTIONS.length > 0) {
    DIETARY_RESTRICTIONS.forEach((oRestriction) => {
      aCombined.push(oRestriction.DESCRIPTION);
    });
  }

  if (OTHER_DIETARY_RESTRICTION && aCombined.includes('Other')) {
    aCombined[
      aCombined.indexOf('Other')
    ] = `Other: ${OTHER_DIETARY_RESTRICTION}`;
  }

  let sCombinedSpecialNeeds = aCombined.join(', ');

  if (sCombinedSpecialNeeds && ADDITIONAL_INFO_CONTENT) {
    sCombinedSpecialNeeds += `; ${ADDITIONAL_INFO_CONTENT}`;
  } else if (!sCombinedSpecialNeeds && ADDITIONAL_INFO_CONTENT) {
    sCombinedSpecialNeeds = ADDITIONAL_INFO_CONTENT;
  }

  return sCombinedSpecialNeeds || 'None';
};

// Buddy function to `fnFormatEventRegistrationDataForDad`
export const fnFormatEventRegistrationDataForDad = (aEventsInCart) => {
  const aEvents = aEventsInCart.map((oEventCartItem) => {
    const { oEvent, aGuests } = oEventCartItem.oDetails;
    const aRegistrants = aGuests.map((oGuest) => {
      const { LOOKUPID, NAME, REG_OPTION } = oGuest;
      const oFormattedGuest = {
        LOOKUPID,
        REG_OPTION,
        sGuestName: NAME,
      };
      return oFormattedGuest;
    });
    return {
      EVENT_SLUG: oEvent.SLUG,
      REGISTRATION_TYPE: oEventCartItem.sType,
      TITLE: oEvent.TITLE,
      LOCATION: oEvent.LOCATION,
      sFormattedEventDateAndTime: fnFormatSubEventDateAndTime(
        oEvent.START_DATE_TIME,
        oEvent.END_DATE_TIME,
        oEvent.TIME_ZONE
      ),
      sFormattedCalendarLink: fnBuildGoogleCalendarLink(oEvent),
      sMainEventTitle: oEvent.MAIN_EVENT?.TITLE ?? '',
      REGISTRANTS: aRegistrants,
      DESCRIPTION: oEvent.DESCRIPTION,
    };
  });

  // Filter out event-cancellation types for additional info
  const aAdditionalInfoByGuest = aEventsInCart
    .filter((oEventCartItem) => oEventCartItem.sType !== 'event-cancellation')
    .map((oEventCartItem) => {
      const { aGuests } = oEventCartItem.oDetails;
      return aGuests.map((oGuest) => {
        const { LOOKUPID, NAME, ADDITIONAL_INFO } = oGuest;
        return {
          LOOKUPID,
          sGuestName: NAME,
          DIETARY_RESTRICTIONS: ADDITIONAL_INFO.DIETARY_RESTRICTIONS,
          OTHER_DIETARY_RESTRICTION: ADDITIONAL_INFO.OTHER_DIETARY_RESTRICTION,
          ADDITIONAL_INFO: ADDITIONAL_INFO.ADDITIONAL_INFO_CONTENT,
        };
      });
    })
    .flat();

  const aUniqueGuests = R.uniqBy(R.prop('LOOKUPID'), aAdditionalInfoByGuest);

  const oResult = {
    EVENTS: aEvents,
    ADDITIONAL_INFO_BY_CONSTITUENT: aUniqueGuests,
  };

  return oResult;
};

export default {};

import React, { useEffect, useState, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import useIsUserAuthenticated from 'utils/hooks/useIsUserAuthenticated';
import EventRegistrationFooter from './EventRegistrationFooter';
import RegistrantListTray from './RegistrantListTray';
import EventRegistrationContext from '../EventRegistrationContext';
import EventRegistrationNavBar from './EventRegistrationNavBar';
import { selectAllEventsInCart } from '../redux/selectors';
import EventRegistrationAlert from './EventRegistrationAlert';
import { fnGetEventStatus } from './helpers';

const EventRegistrationContainer = () => {
  const [sCurrentFormId, fnSetCurrentFormId] = useState('');
  const [bIsSubmittingCurrentForm, fnSetIsSubmittingCurrentForm] =
    useState(false);
  const oLocation = useLocation();
  const aEventsInCart = useSelector(selectAllEventsInCart);
  const [aNonRegistrableEventStatuses, fnSetNonRegistrableEventStatuses] =
    useState([]);
  const bIsUserAuthenticated = useIsUserAuthenticated();

  // Scroll to top of page on load
  useEffect(() => {
    document.querySelector('body').scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [oLocation.pathname]);

  // Check each event's status on the review page
  useEffect(() => {
    const bIsReviewPage = /\/events\/register\/review$/.test(
      oLocation.pathname
    );
    if (
      bIsReviewPage &&
      aEventsInCart.length > 0 &&
      bIsUserAuthenticated !== null
    ) {
      aEventsInCart.forEach(async (oEventInCart) => {
        const { SLUG } = oEventInCart.oDetails.oEvent;
        const { aGuests } = oEventInCart.oDetails;
        const oEventStatus = await fnGetEventStatus(
          SLUG,
          aGuests,
          bIsUserAuthenticated
        );
        if (!oEventStatus.IS_EVENT_REGISTRABLE) {
          fnSetNonRegistrableEventStatuses((aPreviousValue) => [
            ...aPreviousValue,
            oEventStatus,
          ]);
        }
      });
    }
  }, [oLocation.pathname, aEventsInCart, bIsUserAuthenticated]);

  const oMemoizedContextValue = useMemo(
    () => ({
      sCurrentFormId,
      fnSetCurrentFormId,
      bIsSubmittingCurrentForm,
      fnSetIsSubmittingCurrentForm,
      fnResetCurrentFormMeta: () => {
        fnSetCurrentFormId('');
        fnSetIsSubmittingCurrentForm(false);
      },
    }),
    [sCurrentFormId, bIsSubmittingCurrentForm]
  );

  const bIsConfirmationPage = /\/events\/register\/confirmation$/.test(
    window.location.pathname
  );
  return (
    <EventRegistrationContext.Provider value={oMemoizedContextValue}>
      <div className='eventRegistrationContainer'>
        {aNonRegistrableEventStatuses.length > 0 && (
          <EventRegistrationAlert
            oEventRegistrationStatus={aNonRegistrableEventStatuses[0]}
            fnSetNonRegistrableEventStatuses={fnSetNonRegistrableEventStatuses}
          />
        )}
        <EventRegistrationNavBar />
        <div className='eventRegistrationContainer__content'>
          <Outlet />
        </div>
        <RegistrantListTray />
        {!bIsConfirmationPage && (
          <EventRegistrationFooter
            fnSetNonRegistrableEventStatuses={fnSetNonRegistrableEventStatuses}
          />
        )}
      </div>
    </EventRegistrationContext.Provider>
  );
};

export default EventRegistrationContainer;
